import { getColorByUrgency, IconActionItems, IconImportance, Text } from '@cyberpion/cyberpion-ui';
import { Common } from 'common/services/common.service';
import Tooltip from '@mui/material/Tooltip';
import { useDispatch } from 'react-redux';
import { setDelay, showCustomSuccess } from 'features/AlertSlice';
import classNames from 'classnames';
import './PopSideHeader.scss';

const iconGreater = (
  <svg xmlns="http://www.w3.org/2000/svg" width="5" height="8" viewBox="0 0 5 8" fill="none">
    <path d="M1 1L4 4L1 7" stroke="black" stroke-linecap="round" />
  </svg>
);

const iconLink = (
  <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.00019 7.49951L3.70745 6.20676C3.31692 5.81624 3.31692 5.18308 3.70745 4.79255L6.79289 1.70711C7.18342 1.31658 7.81658 1.31658 8.20711 1.70711L8.79274 2.29274C9.18327 2.68327 9.18327 3.31643 8.79274 3.70696L7.99996 4.49973"
      stroke="#3659DE"
    />
    <path
      d="M5.99981 4.50049L7.29255 5.79324C7.68308 6.18376 7.68308 6.81692 7.29255 7.20745L4.20711 10.2929C3.81658 10.6834 3.18342 10.6834 2.79289 10.2929L2.20726 9.70726C1.81673 9.31673 1.81673 8.68357 2.20726 8.29304L3.00004 7.50027"
      stroke="#3659DE"
    />
  </svg>
);

const HeaderDetails = ({ data }: { data: any }) => {
  const urgencyColor = getColorByUrgency(data?.urgency?.type);
  // const isClosed = window.location.pathname.includes('/closed');

  return (
    <div className="header-details">
      {!!data?.urgency && (
        <>
          <div className="left-indicator-line" style={{ backgroundColor: urgencyColor }} />
          <div className="header-urgency">
            <Text weight={500} family="Rajdhani" textSize={40} style={{ display: 'flex', justifyContent: 'center' }}>
              {data.urgency.urgency}
            </Text>
            <Text
              textSize={12}
              textColor="#FFF"
              style={{
                backgroundColor: urgencyColor,
                padding: '4px 8px',
                borderRadius: 4,
                textTransform: 'capitalize'
              }}
            >
              {data.urgency.type}
            </Text>
          </div>
        </>
      )}
      <div style={{ backgroundColor: '#e7e7e7', height: '100%', width: 1 }} />
      <div className="right-side">
        <Text weight={500} textSize={18} style={{ paddingBottom: 8 }}>
          {data.title}
        </Text>
        <div className="chips-details">
          {!!data.first_opened_at && (
            <Text
              textSize={13}
              textColor="#6D6D7A"
              style={{ padding: '4px 8px', borderRadius: 4, backgroundColor: '#F5F6F8', marginRight: 12 }}
            >
              {`Open date: ${Common.getShortDate(data.first_opened_at)}`}
            </Text>
          )}
          {!!data.last_opened_at && (
            <Text
              textSize={13}
              textColor="#6D6D7A"
              style={{ padding: '4px 8px', borderRadius: 4, backgroundColor: '#F5F6F8', marginRight: 12 }}
            >
              {`Last open date: ${Common.getShortDate(data.last_opened_at)}`}
            </Text>
          )}
          {!data.last_closed_at && !!data.last_validate && (
            <Text
              textSize={13}
              textColor="#6D6D7A"
              style={{ padding: '4px 8px', borderRadius: 4, backgroundColor: '#F5F6F8', marginRight: 12 }}
            >
              {`Last validated: ${Common.getShortDate(data.last_validate)}`}
            </Text>
          )}
          {!!data.last_closed_at && (
            <Text
              textSize={13}
              textColor="#6D6D7A"
              style={{ padding: '4px 8px', borderRadius: 4, backgroundColor: '#F5F6F8', marginRight: 12 }}
            >
              {`Closed date: ${Common.getShortDate(data.last_closed_at)}`}
            </Text>
          )}
        </div>
      </div>
    </div>
  );
};

const PopSideHeader = ({
  data,
  showActions,
  onNavigate,
  currentIndex,
  isLastIndex
}: {
  data: any;
  showActions: boolean;
  onNavigate: (isUp: boolean) => void;
  currentIndex?: number | null;
  isLastIndex?: boolean;
}) => {
  const dispatch = useDispatch<any>();
  const tt = data?.asset_importance ? <div>{`${data?.asset_importance?.type} Asset Importance`}</div> : <div />;

  const handleCopyLinkOnClick = () => {
    const isQ = window.location.href.includes('?');
    const copy = `${window.location.href}&ai_id=${data.id}${isQ ? '&' : '?'}open_popside=true&is_open=${
      !!data.last_closed_at ? 'false' : 'true'
    }`;
    navigator.clipboard.writeText(copy);
    dispatch(setDelay(4000));
    dispatch(
      showCustomSuccess({
        title: 'Generated link',
        text: 'Link copied to clipboard successfully.'
      })
    );
  };

  const handleNavigateActionItems = (btn: string) => () => {
    onNavigate(btn === 'up');
  };

  return (
    <div className="ActionItemsPopSideHeader">
      <div className="header-row">
        <IconActionItems color="#3455dd" />
        <Text textSize={13} weight={500} style={{ marginLeft: 6, paddingRight: 8 }}>
          Action Item
        </Text>
        {/* <div className="icon-greater">{iconGreater}</div> */}
        <div className="sides-border">
          <Text textSize={13} weight={500}>
            {data.type}
          </Text>
        </div>
        {data?.asset_importance && (
          <Tooltip
            title={tt}
            placement="bottom"
            arrow
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: '#4D4C53',
                  color: '#FFFFFF',
                  padding: '3px 6px',
                  '& .MuiTooltip-arrow': {
                    color: '#4D4C53'
                  },
                  border: '2px solid black',
                  borderRadius: 1
                }
              }
            }}
          >
            <div style={{ marginLeft: 6 }}>
              <IconImportance type={data?.asset_importance?.type} />
            </div>
          </Tooltip>
        )}
        <Tooltip
          title={data.asset}
          placement="bottom"
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: '#4D4C53',
                color: '#FFFFFF',
                padding: '3px 6px',
                '& .MuiTooltip-arrow': {
                  color: '#4D4C53'
                },
                border: '2px solid black',
                borderRadius: 1
              }
            }
          }}
        >
          <div>
            <Text
              weight={500}
              textSize={13}
              style={{
                marginLeft: 6,
                maxWidth: 300,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              {data.asset}
            </Text>
          </div>
        </Tooltip>
        <div style={{ flexGrow: 1 }} />

        <div className="action-header-buttons">
          <div className="copy-link" onClick={handleCopyLinkOnClick}>
            {iconLink}
            <Text textSize={13} textColor="#4A79E7" style={{ paddingLeft: 4 }}>
              Copy link to action item
            </Text>
          </div>
          {showActions && (
            <>
              <div
                className={classNames('btn-popside-navigate up', { disabled: currentIndex === 0 })}
                onClick={handleNavigateActionItems('up')}
              >
                {iconGreater}
              </div>
              <div
                className={classNames('btn-popside-navigate down', { disabled: !!isLastIndex })}
                onClick={handleNavigateActionItems('down')}
              >
                {iconGreater}
              </div>
            </>
          )}
        </div>
      </div>
      <HeaderDetails data={data} />
    </div>
  );
};

export default PopSideHeader;
