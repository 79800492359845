import './SeedData.scss';
import { Header } from './Header/Header';
import { SubHeader } from './SubHeader/SubHeader';
import { MainSeedFlow } from './MainSeedFlow/MainSeedFlow';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { getSeedData, setSeedDataAdditionalInfoToolTip } from 'features/MSSPSlice';
import { ActionsRow } from './ActionsRow/ActionsRow';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { IReduxState } from 'app/store';
import { setDelay, showError } from 'features/AlertSlice';
import { SeedDataAdditionalInfo } from './MainSeedFlow/DragDrop/SeedDataAdditionalInfo/SeedDataAdditionalInfo';

export function SeedData(props: any) {
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const ldClient = useLDClient();
  const { user } = useSelector((state: IReduxState) => state.login);
  const { seedDataAdditionalInfoToolTip } = useSelector((state: IReduxState) => state.mssp);

  const dispatch = useDispatch<any>();

  useEffect(() => {
    if (ldClient) {
      if (user.mssp_company_name && user.mssp_company_hash) {
        ldClient.identify({ key: user.mssp_company_name }, user.mssp_company_hash);
      } else if (user.company_name && user.company_hash) {
        ldClient.identify({ key: user.company_name }, user.company_hash);
      }
    }
    return () => {
      if (ldClient && user.company_name && user.company_hash) {
        ldClient.identify({ key: user.company_name }, user.company_hash);
      }
    };
  }, [user, ldClient]);

  useEffect(() => {
    const fetch = async () => {
      const res = await dispatch(getSeedData());
      if (res && typeof res.payload === 'string') {
        dispatch(setDelay(12000));
        dispatch(showError(res.payload));
      }
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsPopupOpen(!!Object.keys(seedDataAdditionalInfoToolTip).length);
  }, [seedDataAdditionalInfoToolTip]);

  return (
    <div className={classNames('SeedData')}>
      {!!Object.keys(seedDataAdditionalInfoToolTip).length && (
        <SeedDataAdditionalInfo
          style={seedDataAdditionalInfoToolTip.offset}
          item={seedDataAdditionalInfoToolTip.item}
          parentEntity={seedDataAdditionalInfoToolTip.parent_entity}
          onClose={() => {
            dispatch(setSeedDataAdditionalInfoToolTip({}));
          }}
        />
      )}
      <Header />
      <SubHeader onPopupOpen={setIsPopupOpen} />
      <MainSeedFlow isPopupOpen={isPopupOpen} />
      <ActionsRow />
    </div>
  );
}
