import {
  DragAndDrop,
  DragElement,
  DragPreviewLayer,
  DropArea,
  HierarchyItems,
  LabelWithConfidence,
  LabelWithIcons
  // LabelWithIcons
} from '@cyberpion/cyberpion-ui';
import './DragDrop.scss';
import { useEffect, useRef, useState } from 'react';
import { AddEntityWidget } from '../AddEntityWidget/AddEntityWidget';
import classNames from 'classnames';
// import { CustomSwitch } from 'common/components/CustomSwitch/CustomSwitch';
import { USER_ICON } from '../../SubHeader/AdditionalSeedAssets/AdditionalSeedAssets';
import { DragPreviewElement } from './DragPreviewElement';
import { useDispatch, useSelector } from 'react-redux';
import { IReduxState } from 'app/store';
// import { SeedDataAdditionalInfo } from './SeedDataAdditionalInfo/SeedDataAdditionalInfo';
import { setSeedDataAdditionalInfoToolTip } from 'features/MSSPSlice';
import { ToolTip } from 'common/components/toolTip/toolTip';
// import { USER_ICON } from '../../SubHeader/AdditionalSeedAssets/AdditionalSeedAssets';

export const STATUSES = {
  CONFIRMED: 'CONFIRMED',
  SUGGESTED: 'PROPOSED',
  REJECTED: 'REJECTED',
  MANUAL: 'MANUAL'
};

export const CONFIDENCE_LEVEL: any = {
  1: 'verylow',
  2: 'low',
  3: 'moderate',
  4: 'high',
  5: 'veryhigh'
};

export function DragDrop(props: any) {
  const [showAddEntity, setShowAddEntity] = useState<boolean>(false);
  const [hideDetermined /*, setHideDetermined*/] = useState<boolean>(false);
  const [dragCount, setDragCount] = useState<number>(0);
  const [currentDragStatus, setCurrentDragStatus] = useState<string>('');
  // const [tooltipPosition, setTooltipPosition] = useState<any>({});
  // const [tooltipData, setTooltipData] = useState<any>({});
  const { idsDict, selectedItems, seedData } = useSelector((state: IReduxState) => state.mssp);
  const dispatch = useDispatch<any>();
  const ref: any = useRef(null);

  useEffect(() => {
    document.ondragstart = (e: any) => {
      const classList = e.target.classList;
      const status = classList.contains(STATUSES.CONFIRMED)
        ? STATUSES.CONFIRMED
        : classList.contains(STATUSES.SUGGESTED)
        ? STATUSES.SUGGESTED
        : classList.contains(STATUSES.REJECTED)
        ? STATUSES.REJECTED
        : '';
      setCurrentDragStatus(status);
      dispatch(setSeedDataAdditionalInfoToolTip({}));
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDrop = (item: any, source: string, type: string) => {
    let ids = [item.id];

    if (source === type) {
      return;
    }

    if (ids[0].startsWith('{')) {
      const entity = idsDict[ids[0].replace('{', '').replace('}', '')];
      if (entity.source === STATUSES.MANUAL && type === STATUSES.SUGGESTED) {
        return;
      }
      const names = entity.names
        .filter((n: any) => {
          return n.verdict === source;
        })
        .map((n: any) => n.id);
      const domains = entity.domains
        .filter((d: any) => {
          return d.verdict === source;
        })
        .map((d: any) => d.id);

      ids = [...names, ...domains];
    }

    if (idsDict[ids[0]].verdict_source === STATUSES.MANUAL && type === STATUSES.SUGGESTED) {
      return;
    }

    props.onFinishDrag([...ids], source, type);
    setDragCount(dragCount + 1);
  };

  const getItems = (status: string): JSX.Element[] => {
    const html: JSX.Element[] = [];
    if (Object.keys(props.data).length) {
      Object.keys(props.data)
        .sort((a: any) => {
          if (props.data[a].source === STATUSES.MANUAL) {
            return -1;
          } else {
            return 1;
          }
        })
        .forEach((id: any) => {
          const getParents = (parents: []) => {
            const _html: JSX.Element[] = [];
            parents.forEach((parent: any, index: number) => {
              _html.push(
                <DragElement id={parent.id} source={status} onFinish={onDrop} key={parent.id + dragCount}>
                  {parent.data_source === STATUSES.MANUAL ? (
                    <LabelWithIcons label={parent.name} icons={[USER_ICON]} />
                  ) : (
                    <div style={{ display: 'flex' }}>
                      <LabelWithConfidence
                        onClick={() => (status === STATUSES.SUGGESTED ? props.onSelection(parent.id) : {})}
                        className={classNames({
                          // moved: props.data.approved[item] || props.data.rejected[item],
                          itemSelected: selectedItems.includes(parent.id)
                          // hidden: props.search && !item.toLowerCase().includes(props.search)
                        })}
                        level={CONFIDENCE_LEVEL[parent.confidence]}
                        label={parent.name}
                      />
                      {index === 0 && (
                        <img
                          src="/assets/images/info.svg"
                          style={{ marginLeft: 5, cursor: 'pointer' }}
                          onClick={(e: any) => {
                            showToolTip(e, parent);
                          }}
                          alt="Info"
                        />
                      )}
                    </div>
                  )}
                </DragElement>
              );
            });

            return _html;
          };
          const getChildrens = (children: []) => {
            const _html: JSX.Element[] = [];
            children.forEach((child: any) => {
              _html.push(
                <DragElement id={child.id} source={status} onFinish={onDrop} key={child.id + dragCount}>
                  {child.data_source === STATUSES.MANUAL ? (
                    <LabelWithIcons label={child.domain} icons={[USER_ICON]} />
                  ) : (
                    <LabelWithConfidence
                      onClick={() => (child.verdict === STATUSES.SUGGESTED ? props.onSelection(child.id) : {})}
                      level={''}
                      className={classNames({
                        // moved: props.data.approved[child] || props.data.rejected[child],
                        itemSelected: selectedItems.includes(child.id)
                        // hidden: props.search && !child.includes(props.search)
                      })}
                      label={child.domain}
                      showIcon
                      hideConfidence
                    />
                  )}
                </DragElement>
              );
            });
            return _html;
          };

          const domains = props.data[id].domains.filter((d: any) => {
            return d.verdict === status && d.visible;
          });
          const names = props.data[id].names.filter((d: any) => {
            return d.verdict === status && d.visible;
          });

          if (domains.length || names.length) {
            html.push(
              <DragElement
                className={status}
                id={`{${id}}`}
                onFinish={onDrop}
                key={id + dragCount + 'group'}
                source={status}
              >
                <HierarchyItems
                  data={{
                    top: getParents(names),
                    bottom: getChildrens(domains)
                  }}
                  onlyBottomElements={domains.length && !names.length}
                />
              </DragElement>
            );
          }

          // }
        });
    }

    return html;
  };

  const showToolTip = (event: any, item: any) => {
    const position = event.target.getBoundingClientRect();
    const offset = {
      top: position.top + position.height * 0.5,
      left: position.left + position.width * 1.5
    };
    if (window.innerHeight - position.top < 130) {
      offset.top = position.top - position.height * 3;
    }
    if (window.innerWidth - position.left < 250) {
      offset.left = position.left - position.width - 220;
    }
    dispatch(
      setSeedDataAdditionalInfoToolTip({ offset: offset, item: item, parent_entity: props.data[item.parent_entity_id] })
    );
  };

  const hasStatus = (status: string) => {
    let hasStatus = false;
    for (let entity in props.data) {
      if (
        props.data[entity].domains.filter((d: any) => {
          return d.verdict === status;
        }).length ||
        props.data[entity].names.filter((d: any) => {
          return d.verdict === status;
        }).length
      ) {
        hasStatus = true;
      }
    }
    return hasStatus;
  };

  const selectAll = () => {
    const ids: string[] = [];
    for (let entity in props.data) {
      [...props.data[entity].domains, ...props.data[entity].names].forEach((item: any) => {
        if (item.verdict === STATUSES.SUGGESTED && item.visible) {
          ids.push(item.id);
        }
      });
    }
    props.onSelectAll(ids);
  };
  return (
    <div className="DragDrop">
      <AddEntityWidget
        onSubmit={(value: string, type: string) => {
          props.onSubmitNew(value, type);
          ref.current.scrollTo({ top: 0, behavior: 'smooth' });
        }}
        visible={showAddEntity}
        onClose={() => setShowAddEntity(false)}
      />
      <DragAndDrop>
        <DragPreviewLayer
          previewElement={(item: string) => (
            <DragPreviewElement
              idsDict={idsDict}
              source={currentDragStatus}
              item={item}
              selectedItems={selectedItems}
            />
          )}
        />
        <div className="left">
          <div className="proposed">
            <DropArea type={STATUSES.SUGGESTED}>
              <header>
                <div>
                  <img src="/assets/images/list-2.svg" alt="List" />
                  Proposed
                </div>
                {/* <div className="switch-wrapper">
                  <span className="label">Hide determined</span>
                  <CustomSwitch
                    checked={hideDetermined}
                    onChange={(e: any, state: boolean) => setHideDetermined(state)}
                  />
                </div> */}
                {!!props.search && (
                  <div>
                    <img
                      src="/assets/images/select.svg"
                      data-tip="Select all"
                      data-for="SEED_BULK_ACTION"
                      className="select-all"
                      onClick={selectAll}
                      alt="Select All"
                    />
                    <img
                      src="/assets/images/unselect.svg"
                      className="unselect-all"
                      onClick={() => props.onSelectAll([])}
                      alt="Unselect All"
                      data-tip="Unselect all"
                      data-for="SEED_BULK_ACTION"
                    />
                    <ToolTip id="SEED_BULK_ACTION" place="top" theme="dark" effect="solid" />
                  </div>
                )}
              </header>
              <div className="scrollbar-common" onMouseEnter={props.disableScroll} onMouseLeave={props.enableScroll}>
                <div className={classNames('main-scrollable-area', { 'hide-determined': hideDetermined })}>
                  {getItems(STATUSES.SUGGESTED)}
                </div>
              </div>
            </DropArea>
          </div>
        </div>
        <div className="right">
          <div className="approve">
            <DropArea type={STATUSES.CONFIRMED}>
              <header>
                <div>
                  <img src="/assets/images/ok-green.svg" alt="Ok" />
                  Approved
                </div>
                {Array.isArray(seedData) && !!seedData.length && (
                  <span
                    className="add-entity"
                    onClick={(e: any) => {
                      e.preventDefault();
                      setShowAddEntity(!showAddEntity);
                    }}
                  >
                    Add Entity
                  </span>
                )}
              </header>
              <div
                className="scrollbar-common"
                ref={ref}
                onMouseEnter={props.disableScroll}
                onMouseLeave={props.enableScroll}
              >
                {!hasStatus(STATUSES.CONFIRMED) ? (
                  <div className="empty-state">
                    <img src="/assets/images/dnd.svg" alt="Drag and Drop here" />
                    <p>Drag & Drop items here</p>
                  </div>
                ) : (
                  <div className="main-scrollable-area">{getItems(STATUSES.CONFIRMED)}</div>
                )}
              </div>
            </DropArea>
          </div>
          <div className="reject">
            <DropArea type={STATUSES.REJECTED}>
              <header>
                <div>
                  <img src="/assets/images/trash.svg" alt="Trash" />
                  Rejected
                </div>
              </header>
              <div className="scrollbar-common" onMouseEnter={props.disableScroll} onMouseLeave={props.enableScroll}>
                {!hasStatus(STATUSES.REJECTED) ? (
                  <div className="empty-state">
                    <img src="/assets/images/dnd.svg" alt="Drag and Drop here" />
                    <p>Drag & Drop items here</p>
                  </div>
                ) : (
                  <div className="main-scrollable-area">{getItems(STATUSES.REJECTED)}</div>
                )}
              </div>
            </DropArea>
          </div>
        </div>
      </DragAndDrop>
    </div>
  );
}
