import { useEffect, useState } from 'react';
import CnappAlertHeader from './CnappAlertHeader';
import './CnappAlertPopside.scss';
import { RestApi } from 'common/services/rest-api.service';
import { AppLoading } from 'common/components/appLoading/appLoading';
import CNAPPAlertTabs from './CNAPPAlertTabs';
import CNAPPAlertGeneralTab from './Tabs/CNAPPAlertGeneralTab';
import CNAPPAlertRawTab from './Tabs/CNAPPAlertRawTab';

const CnappAlertPopside = (props: any) => {
  const [data, setData] = useState<any>(props.data);
  const [loading, setLoading] = useState<boolean>(false);
  const [tabActive, setTabActive] = useState('general');
  const [currentIndex, setCurrentIndex] = useState<number | null>(
    props.rowsIds?.findIndex((id: string) => id === props.data.id) || null
  );

  useEffect(() => {
    if (!!props.rowsIds && !!props.rowsIds?.length) {
      setCurrentIndex(props.rowsIds?.findIndex((id: string) => id === data.id));
    }
  }, [props.rowsIds, data.id]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      const row: HTMLElement | null = document.querySelector(`[data-id~='${data.id}']`);
      if (!!row) {
        const newDiv = document.createElement('div');
        newDiv.className = 'action-item-row-selected';
        newDiv.style.width = '6px';
        newDiv.style.height = '52px';
        newDiv.style.backgroundColor = '#3455dd';
        newDiv.style.position = 'sticky';
        newDiv.style.left = '0px';
        newDiv.style.borderRadius = '0 4px 4px 0';
        row.insertBefore(newDiv, row.firstChild);
        row.style.backgroundColor = '#e8e8e8';
      }
    }, 1000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnNavigate = async (isUp: boolean) => {
    document.querySelector('.action-item-row-selected')?.remove();
    setLoading(true);
    setTabActive('general');

    const currInx = props.rowsIds.findIndex((id: string) => id === data.id);

    if (isUp) {
      if (currInx === 0) {
        return;
      }
      await fetchData(props.rowsIds[currInx - 1]);
      setCurrentIndex(currInx - 1);
    } else {
      if (currInx === props.rowsIds.length - 1) {
        return;
      }
      await fetchData(props.rowsIds[currInx + 1]);
      setCurrentIndex(currInx + 1);
    }
  };

  const fetchData = (id: string) => {
    let url = `alerts/${id}/`;
    RestApi.getData(url).subscribe(
      (response: any) => {
        setData(response);
        setLoading(false);
        const row: HTMLElement | null = document.querySelector(`[data-id~='${id}']`);
        if (!!row) {
          const newDiv = document.createElement('div');
          newDiv.className = 'action-item-row-selected';
          newDiv.style.width = '6px';
          newDiv.style.height = '52px';
          newDiv.style.backgroundColor = '#3455dd';
          newDiv.style.position = 'sticky';
          newDiv.style.left = '0px';
          newDiv.style.borderRadius = '0 4px 4px 0';
          row.insertBefore(newDiv, row.firstChild);
          row.style.backgroundColor = '#e8e8e8';
        }
      },
      error => {}
    );
  };

  return (
    <div className="CnappAlertPopside">
      <CnappAlertHeader
        data={data}
        onNavigate={handleOnNavigate}
        currentIndex={currentIndex}
        isLastIndex={currentIndex === props.rowIds?.length - 1}
      />
      <CNAPPAlertTabs data={data} tabActive={tabActive} setTabActive={setTabActive} />
      <div className="scrollbar-common">
        <div className="popside-content">
          {loading && <AppLoading />}
          {tabActive === 'general' && <CNAPPAlertGeneralTab data={data} />}
          {tabActive === 'raw' && <CNAPPAlertRawTab data={data} />}
        </div>
      </div>
    </div>
  );
};

export default CnappAlertPopside;
